import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showAvailableReports } from 'reducers/DialogsReducer';
import QuickFilter from 'components/QuickFilter';
import { displayDateTime } from 'reducers/TimeReducer';
export const columnsItems = [
  { field: 'ReportID', headerName: 'ReportID', width: 120 },
  { field: 'ReportTitle', headerName: 'ReportTitle', width: 200 },
  { field: 'Description', headerName: 'Description', width: 220 },
  { field: 'Type', headerName: 'Type', width: 100 },
  { field: 'Agency', headerName: 'Agency', width: 150 },
  { field: 'FileName', headerName: 'FileName', width: 250 },

  { field: 'DefaultPath', headerName: 'DefaultPath', width: 150 },
  { field: 'DefaultSQL', headerName: 'DefaultSQL', width: 150 },
  { field: 'DefaultParams', headerName: 'DefaultParams', width: 150 },

  { field: 'DataSetName', headerName: 'DataSetName', width: 150 },

  { field: 'IsDeleted', headerName: 'Deleted', width: 150 },
  { field: 'IsActive', headerName: 'Active', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },

  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'CreatedBy', width: 150 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'DeletedBy', width: 150 },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 150,
    valueFormatter: params => displayDateTime(params.value),
    format: 'date',
  },
];
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function RotationSetup(props) {
  const { wsClient, addAvailableReport } = props;
  const classes = useStyles();
  const service = getService('reportcatalog');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (addAvailableReport === null) {
      search();
    }
  }, [wsClient.websocket, addAvailableReport]);
  const search = async () => {
    service.timeout = 20000;
    try {
      const result = await service.find({ query: { IsDeleted: false, Type: 'RMSR', $limit: 0 } });
      setRows(result?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  };
  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const onClickAdd = () => {
    dispatch(showAvailableReports(true));
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.ptsReportID == selection[0]);

    dispatch(showAvailableReports(obj));
  };
  const onClickDelete = async () => {
    const removeRotation = await service.remove(selection[0]);
    const newRows = rows.filter(el => el.ptsReportID !== removeRotation.ptsReportID);
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = rows?.find(a => a.ptsReportID === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h4>Rotation</h4>
        {renderActions()}
        <div className={classes.gridWrap}>
          <XGrid
            columns={columnsItems}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            getRowId={row => row.ptsReportID}
            // checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    addAvailableReport: state.dialogs.addAvailableReport,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(RotationSetup);
